import React from 'react'
import { I18nProvider } from '@lingui/react'
import { QueryClientProvider } from 'react-query'
import NotificationsProvider from './providers/NotificationsProvider'
import GraphqlClientProvider from './providers/GraphqlClientProvider'
import StoryblokClientProvider from './providers/StoryblokClientProvider'
import MyStatsigProvider from './providers/MyStatsigProvider'

const AppProviders = ({
    i18n,
    userUid,
    children,
    queryClient,
    graphqlClient,
    storyblokClient,
    notificationDispatcher,
}) => (
    <MyStatsigProvider userUid={userUid}>
        <GraphqlClientProvider value={graphqlClient}>
            <StoryblokClientProvider value={storyblokClient}>
                <QueryClientProvider client={queryClient}>
                    <I18nProvider i18n={i18n}>
                        <NotificationsProvider
                            dispatcher={notificationDispatcher}
                        >
                            {children}
                        </NotificationsProvider>
                    </I18nProvider>
                </QueryClientProvider>
            </StoryblokClientProvider>
        </GraphqlClientProvider>
    </MyStatsigProvider>
)

export default AppProviders
