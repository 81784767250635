import React from 'react'
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings'

export default function MyStatsigProvider({ userUid, children }) {
    const { client } = useClientAsyncInit(
        process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY,
        {
            userID: userUid,
            environment: { tier: process.env.NEXT_PUBLIC_APP_ENV },
        }
    )

    return <StatsigProvider client={client}>{children}</StatsigProvider>
}
